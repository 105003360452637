<template>
  <div v-if="!isAddAllColumn">
    <b-button class="btn-visible" variant="link" @click="isToggle = !isToggle">
      <feather-icon
        icon="SettingsIcon"
        size="17"
        class="align-middle text-body text-primary"
      />
    </b-button>

    <b-sidebar v-model="isToggle" bg-variant="white" right backdrop shadow>
      <div class="p-2">
        <h3 class="text-center pb-2">{{ $t("general.columnSetting") }}</h3>
        <div v-for="(field, index) in columns" :key="`column-${index}`">
          <b-form-checkbox
            v-model="field.isShow"
            class="text-left pb-1"
            @change="onChange($event, field)"
            v-if="!field.hideToggle"
          >
            {{ $t(field.label) }}
          </b-form-checkbox>
        </div>
        <b-button variant="primary" block @click="checkedAllColumn">
          {{ $t("button.selectAll") }}
        </b-button>
        <b-button variant="danger" block @click="resetColumn">
          {{ $t("button.reset") }}
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BFormCheckbox,
  VBToggle,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSidebar,
    BRow,
    BCol,
  },
  props: {
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    visibleColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      key: 1,
      columns: [],
      showFields: [],
      isAddAllColumn: true,
      isToggle: false,
    };
  },
  mounted() {
    this.checkVisibleField();
    let hideToggleFields = [];
    for (let i = 0; i < this.fields.length; i++) {
      if (!this.fields[i].hideToggle) {
        this.isAddAllColumn = false;
      }
      if (this.fields[i].hideToggle) {
        hideToggleFields.push(this.fields[i].key);
      }
    }
    if (!this.visibleColumns.length || this.isAddAllColumn) {
      this.resetColumn();
    } else {
      for (let j = 0; j < hideToggleFields.length; j++) {
        if (!this.visibleColumns.includes(hideToggleFields[j])) {
          this.resetColumn();
          break;
        }
      }
    }
  },
  methods: {
    checkedAllColumn() {
      let visibleColumns = [];
      let columns = [];
      this.fields.forEach((element) => {
        visibleColumns.push(element.key);

        if (!element.hideToggle) {
          columns.push({
            ...element,
            isShow: true,
          });
        }
      });
      this.columns = [...columns];
      this.key++;
      this.$emit("input", visibleColumns);
      this.$emit("change");
    },
    resetColumn() {
      let visibleColumns = [];
      let columns = [];
      this.fields.forEach((element) => {
        if (element.hideToggle) {
          visibleColumns.push(element.key);
        } else {
          columns.push({
            ...element,
            isShow: false,
          });
        }
      });
      this.columns = [...columns];
      this.key++;
      this.$emit("input", visibleColumns);
      this.$emit("change");
    },
    checkVisibleField() {
      let columns = [];
      this.fields.forEach((element) => {
        if (!element.hideToggle) {
          columns.push({
            ...element,
            isShow: this.visibleColumns.includes(element.key),
          });
        }
      });
      this.columns = [...columns];
    },
    onChange(checked, field) {
      let visibleColumns = [...this.visibleColumns];
      const index = visibleColumns.findIndex((element) => {
        return element == field.key;
      });
      if (index != -1) {
        visibleColumns.splice(index, 1);
      } else {
        visibleColumns.push(field.key);
      }
      this.$emit("input", visibleColumns);
      this.$emit("change", field, checked);
    },
  },
  setup() {},
};
</script>